<template>
    <div style="background: #f8f8f8">
        <s-header :name="'我的订单'"></s-header>
        <van-tabs v-model:active="active" @click-tab="onClickTab">
            <van-tab title="全部"></van-tab>
            <van-tab title="待付款"></van-tab>
            <van-tab title="待发货"></van-tab>
            <van-tab title="待收货"></van-tab>
            <van-tab title="已完成"></van-tab>
        </van-tabs>
        <ul style="margin: 20px 0">
            <li class="list" v-for="val in list" :key="val.id">
                <p class="orderNum">订单号： {{ val.order_sn }}</p>
                <div class="orderInfo" v-for="item in val.product_data" :key="item.id">
                    <img width="100" :src="item.product_picture" />
                    <div style="margin-left: 10px">
                        <p>{{ item.product_name }}</p>
                        <p style="color: #999">{{item.sku_name || '基础版'}}</p>
                        <p style="color: #fa436a">￥<span style="font-size: 18px">{{ item.price }}</span><span style="color: #333"> * {{ item.num }}</span></p>
                    </div>
                </div>
                <div class="orderPrice">
                    <span>运费 <span style="color: #333">￥{{ val.shipping_money }}</span></span>
                    <span>共 <span style="color: #333">{{ val.product_count }}</span> 件商品</span>
                    <span>{{ val.order_status == 1 ? '实付款' : '待付款'}} <span style="color: #333">￥{{ Number(val.shipping_money) + Number(val.product_money) }}</span></span>
                </div>
                <div class="orderBtn">
                    <van-tag v-if="val.order_status == -4" type="danger" round>已取消</van-tag>
                    <van-button v-if="val.order_status == -4 || val.order_status == 1" @click="onClose(val.id)">取消订单</van-button>
                    <van-button style="margin: 0 10px" @click="gotoOrder(val.id)">订单详情</van-button>
                    <van-button v-if="val.order_status == -4" :disabled="val.order_status == -4 ? 'disabled' : false" color="#fa436a" @click="gotoPay(val.id)">立即支付</van-button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    import sHeader from '@/components/SimpleHeader'
    import { orderList, closeOrder } from '@/service/order'
    import {useRouter} from "vue-router";
    import { onMounted, ref } from 'vue'
    export default {
        components: {
            sHeader
        },
        name: "OrderList",
        setup() {
            const router = useRouter()
            const val = ref(0)
            const active = ref(0);
            const list = ref([])
            onMounted( () => {
                val.value = router.currentRoute.value.query.type;
                getData(val.value)
            })
            async function getData(num) {
                const obj = {
                    page: 1,
                    synthesize_status: num
                }
                const { data } = await orderList(obj)
                active.value = Number(num) + 1
                list.value = data
            }
            function onClickTab(val) {
                const num = val.name
                val.value = (Number(num) - 1)
                if(num == 0) {
                    getData()
                } else {
                    getData((Number(num) - 1))
                }
            }
            async function onClose(id) {
                const { data } = await closeOrder({id: id})
                if(val.value == 0) {
                    getData()
                } else {
                    getData((Number(val.value) - 1))
                }
                console.log(data);
            }
            function gotoOrder(id) {
                router.push({path: '/orderDetails', query: {id: id}})
            }
            function gotoPay(id) {
                router.push({path: '/good/pay', query: {ids: id, coupon_id: ''}})
            }
            return {
                list,
                val,
                onClickTab,
                getData,
                gotoOrder,
                onClose,
                gotoPay,
                active
            }
        }
    }
</script>

<style scoped lang="less">
    .list{
        background: #fff;
        .orderNum{
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 20px 10px;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
        }
        .orderInfo{
            display: flex;
            align-items: center;
            padding: 20px 30px;
            border-bottom: 1px solid #ddd;
        }
        .orderPrice{
            display: flex;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 1px solid #ddd;
            color: #999;
            font-size: 16px;
        }
        .orderBtn{
            padding: 10px 20px;
            text-align: right;
        }
        ::v-deep .van-button{
            height: 36px;
            border-radius: 20px;
        }
    }
</style>
